import React, { useState, useEffect } from "react";
import {
  Heading,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import BookingsForm from "../../components/BookingsForm";
import BookingsRow from "../../components/BookingsRow";
import apiUrl from "../../apiConfig";

const BookingsPage = () => {
  const [bookings, setBookings] = useState([]);
  // const addBooking = (newBooking) => {
  //   newBooking.id = Math.random();
  //   setBookings([...bookings, newBooking]);
  // };

  const fetchBookings = async () => {
    try {
      const response = await fetch(`${apiUrl}/bookings`);
      console.log(response);
      if (!response.ok) {
        throw new Error("Error fetching bookings");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching bookings:", error);
      throw error; // Propagate the error for handling in the calling code
    }
  };
  const addBooking = async (newBooking) => {
    try {
      // Send the API call to create a new booking
      const response = await fetch(`${apiUrl}/bookings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newBooking),
      });

      if (!response.ok) {
        console.error("Error adding new booking");
        return;
      }

      // // Wait for the POST request to complete before fetching the updated list
      // await response.json();

      const responseData = await response.json();
      console.log("Response Data after POST:", responseData);

      // Fetch the updated list of bookings after adding the new booking
      const updatedBookings = await fetchBookings();
      setBookings(updatedBookings);
    } catch (error) {
      console.error("Error updating bookings:", error);
    }
  };

  // const editBooking = async (changedBooking) => {
  //   try {
  //     // Send the API call to create a new booking
  //     const response = await fetch(`${apiUrl}/bookings`, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(changedBooking),
  //     });

  //     if (!response.ok) {
  //       console.error("Error adding new booking");
  //       return;
  //     }

  //     // // Wait for the POST request to complete before fetching the updated list
  //     // await response.json();

  //     const responseData = await response.json();
  //     console.log("Response Data after POST:", responseData);

  //     // Fetch the updated list of bookings after adding the edited booking
  //     const updatedBookings = await fetchBookings();
  //     setBookings(updatedBookings);
  //   } catch (error) {
  //     console.error("Error updating bookings:", error);
  //   }
  // };

  useEffect(() => {
    // Fetch bookings from the API when the component mounts
    fetch(`${apiUrl}/bookings`)
      .then((response) => response.json())
      .then((data) => setBookings(data))
      .catch((error) => console.error("Error fetching bookings:", error));
  }, []); // The empty dependency array ensures this effect runs once on component mount

  const handleDelete = (bookingId) => {
    // Send a DELETE request to the API to delete the booking
    fetch(`${apiUrl}/bookings/${bookingId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          // Remove the deleted booking from the state
          setBookings((prevBookings) =>
            prevBookings.filter((booking) => booking.id !== bookingId)
          );
          // addBooking(); // Passing nothing will trigger a re-render
        } else {
          console.error("Error deleting booking");
        }
      })
      .catch((error) => console.error("Error deleting booking:", error));
  };

  // Calculate the total amount
  const totalAmount = bookings.reduce(
    (sum, booking) => sum + booking.amount,
    0
  );

  return (
    <>
      <Heading style={{ marginBottom: 40 }}>Bookings</Heading>
      <Table aria-label="sample table" style={{ marginBottom: "100px" }}>
        <TableHead>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableHeader>Subject</TableHeader>
            <TableHeader>Amount</TableHeader>
            <TableHeader>Categories</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings.map((booking) => (
            <BookingsRow
              key={booking.id}
              booking={booking}
              onDelete={handleDelete}
            />
          ))}
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell></TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Total:</TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                color: totalAmount < 0 ? "red" : "black",
              }}
            >
              {totalAmount} €
            </TableCell>
            <TableCell colSpan={2}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <BookingsForm addBooking={addBooking} />
    </>
  );
};

export default BookingsPage;
