import React from "react";

import { Heading } from "@carbon/react";

const DashboardPage = () => {
  return (
    <>
      <Heading style={{ marginBottom: 40 }}>Dashboard</Heading>
      <p>Welcome to FinCalc</p>
    </>
  );
};

export default DashboardPage;
