import ReactDOM from "react-dom";
import React, { useState, useRef } from "react";
import { Button, Modal } from "@carbon/react";
import { Add } from "@carbon/react/icons";
import BookingsForm from "../BookingsForm";

const BookingsModal = () => {
  /**
   * Simple state manager for modals.
   */
  // const [bookings, setBookings] = useState([]);
  // const updateBookings = (newBooking) => {
  //   setBookings([...bookings, newBooking]);
  // };
  const ModalStateManager = ({
    renderLauncher: LauncherContent,
    children: ModalContent,
  }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        {!ModalContent || typeof document === "undefined"
          ? null
          : ReactDOM.createPortal(
              <ModalContent open={open} setOpen={setOpen} />,
              document.body
            )}
        {LauncherContent && <LauncherContent open={open} setOpen={setOpen} />}
      </>
    );
  };
  const button = useRef();
  return (
    <ModalStateManager
      renderLauncher={({ setOpen }) => (
        <Button
          ref={button}
          onClick={() => setOpen(true)}
          style={{ color: "#161616" }}
        >
          <Add style={{ marginRight: 7 }} />
          Add Booking
        </Button>
      )}
    >
      {({ open, setOpen }) => (
        <Modal
          launcherButtonRef={button}
          modalHeading="Add Booking"
          modalLabel="Awesome booking"
          primaryButtonText="Save Booking"
          secondaryButtonText="Cancel"
          open={open}
          onRequestClose={() => setOpen(false)}
        >
          <BookingsForm />
        </Modal>
      )}
    </ModalStateManager>
  );
};

export default BookingsModal;
