import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Tag,
  OverflowMenu,
  OverflowMenuItem,
  Modal,
  InlineNotification,
} from "@carbon/react";
import { TrashCan } from "@carbon/react/icons";

const BookingsRow = ({ booking, onDelete }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteResult, setDeleteResult] = useState(null);

  const handleDelete = async () => {
    try {
      await onDelete(booking.id);
      setDeleteResult("success");
    } catch (error) {
      console.error("Error deleting booking:", error);
      setDeleteResult("error");
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDeleteResult(null);
  };

  return (
    <>
      <TableRow key={booking.id}>
        <TableCell>{booking.bookingDate}</TableCell>
        <TableCell>{booking.bookingName}</TableCell>
        <TableCell style={{ color: booking.amount < 0 ? "red" : "black" }}>
          {booking.amount}
          {" €"}
        </TableCell>
        <TableCell>
          {booking.bookingCategory ? (
            <Tag>{booking.bookingCategory}</Tag>
          ) : (
            "no category defined"
          )}
        </TableCell>
        <TableCell>
          {/* <Button onClick={handleDelete} style={{ color: "#161616" }}>
          <TrashCan />
        </Button> */}
          {/* <TableCell className="cds--table-column-menu"> */}
          <OverflowMenu size="sm" flipped>
            <OverflowMenuItem itemText="Edit" />
            <OverflowMenuItem itemText="Duplicate" />
            <OverflowMenuItem
              // onClick={handleDelete}
              onClick={handleOpenModal}
              style={{ color: "red" }}
              itemText={
                <>
                  <TrashCan style={{ marginRight: "0.5rem", fill: "red" }} />
                  Delete Entry
                </>
              }
            />
          </OverflowMenu>
          {/* </TableCell> */}
        </TableCell>
      </TableRow>
      {deleteResult === "success" && (
        <InlineNotification
          kind="success"
          title="Booking deleted successfully"
        />
      )}
      {deleteResult === "error" && (
        <InlineNotification
          kind="error"
          title="Error deleting booking"
          subtitle="Please try again later"
        />
      )}

      {isModalOpen && (
        <Modal
          // launcherButtonRef={button}
          modalHeading="Confirm Deletion"
          // modalLabel="Booking"
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          open={isModalOpen}
          onRequestClose={handleCloseModal}
          onRequestSubmit={handleDelete}
        >
          <p>Are you sure you want to delete this booking?</p>
        </Modal>
      )}
    </>
  );
};

export default BookingsRow;
