import React, { useState } from "react";
import {
  Form,
  Button,
  TextInput,
  Stack,
  DatePicker,
  DatePickerInput,
} from "@carbon/react";
import { CheckmarkOutline } from "@carbon/react/icons";

const BookingsForm = ({ addBooking }) => {
  const [date, setDate] = useState(""); // Initialize date as an empty string
  const [subject, setSubject] = useState("");
  const [amount, setAmount] = useState("");
  const [errors, setErrors] = useState({});
  const [customCategory, setCustomCategory] = useState("");

  const validateForm = () => {
    const newErrors = {};

    if (!subject) {
      newErrors.date = "Please select a Subject.";
    }

    if (!date) {
      newErrors.date = "Please select a date.";
    }

    if (!amount) {
      newErrors.amount = "Please enter the amount.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Format the date as YYYY-MM-DD
    const formattedDate = new Date(date).toISOString().split("T")[0];

    // Parse the amount as a float
    const formattedAmount = parseFloat(amount);

    // Create the request body from form data
    const requestBody = {
      bookingName: subject,
      amount: formattedAmount,
      bookingCategory: customCategory,
      bookingDate: formattedDate,
    };

    // Call the parent component's addBooking function with the new booking data
    addBooking(requestBody);

    // Reset form fields
    setCustomCategory("");
    setDate(""); // Reset date to an empty string
    setSubject("");
    setAmount("");
    setErrors({});
  };

  return (
    <Form
      style={{ width: "35%" }}
      aria-label="sample form"
      onSubmit={handleSubmit}
    >
      <Stack>
        {/* <RadioButtonGroup
          style={{ marginBottom: 20 }}
          name="radio-button-group"
          defaultSelected="radio-1"
          onChange={(value) => setSelectedOption(value)}
        >
          <RadioButton labelText="Einnahme" value="radio-1" id="radio-1" />
          <RadioButton labelText="Ausgabe" value="radio-2" id="radio-2" />
        </RadioButtonGroup> */}
        <DatePicker
          style={{ marginBottom: 20 }}
          datePickerType="single"
          selected={date}
          onChange={(newDate) => setDate(newDate)} // Set date as the selected value
        >
          <DatePickerInput
            id="date-picker-single"
            placeholder="mm/dd/yyyy"
            labelText="Date"
            aria-autocomplete="none"
            autoComplete="off"
          />
        </DatePicker>
        <TextInput
          id="booking-subject"
          style={{ marginBottom: 20 }}
          labelText="Subject"
          type="text"
          autoComplete="off"
          // required
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <TextInput
          id="custom-category"
          style={{ marginBottom: 20 }}
          labelText="Category"
          type="text"
          autoComplete="off"
          value={customCategory}
          onChange={(e) => setCustomCategory(e.target.value)}
        />

        <TextInput
          id="booking-amount"
          style={{ marginBottom: 20 }}
          labelText="Amount"
          type="number"
          autoComplete="off"
          required
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        {/* Display validation errors */}
        {errors.selectedOption && (
          <div style={{ color: "red", marginBottom: 10 }}>
            {errors.selectedOption}
          </div>
        )}
        {errors.date && (
          <div style={{ color: "red", marginBottom: 10 }}>{errors.date}</div>
        )}
        {errors.amount && (
          <div style={{ color: "red", marginBottom: 10 }}>{errors.amount}</div>
        )}
        <Button
          type="submit"
          className="some-class"
          style={{ color: "#161616" }}
        >
          <CheckmarkOutline style={{ marginRight: 7 }} />
          Save Booking
        </Button>
      </Stack>
    </Form>
  );
};

export default BookingsForm;
