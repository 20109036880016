import React, { Component } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import { Content } from "@carbon/react";
import HeaderMain from "./components/HeaderMain";
import DashboardPage from "./content/DashboardPage";
import BookingsPage from "./content/BookingsPage";

import "./app.scss";

class App extends Component {
  render() {
    // console.log(process.env.REACT_APP_API_URL_LOCAL);

    return (
      <HashRouter>
        <HeaderMain />
        <Content>
          <Switch>
            <Route exact path="/" component={DashboardPage} />
            <Route path="/bookings" component={BookingsPage} />
          </Switch>
        </Content>
      </HashRouter>
    );
  }
}

export default App;
